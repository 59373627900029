import React, { useState, useEffect } from 'react';

function ModifyLinks() {
  const [formData, setFormData] = useState({
    Platform: 'escala',
    escala: '',
    maharlika: '',
    xtreme: '',
    gc: '' // Added to handle GC link
  });

  useEffect(() => {
    fetch('/links.json')
      .then(response => response.json())
      .then(data => setFormData(data))
      .catch(error => console.error('Error fetching links:', error));
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handlePlatformChange = (e) => {
    setFormData({
      ...formData,
      Platform: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('/modify_json.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Links updated successfully');
        } else {
          console.error('Error updating links:', data.message);
        }
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <div className="container my-5">
      <h2 style={{ color: 'white' }}>Update Links</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="platform" className="form-label" style={{ color: 'white' }}>Platform</label>
          <select className="form-control" id="platform" name="Platform" value={formData.Platform} onChange={handlePlatformChange}>
            <option value="escala">Escala</option>
            <option value="maharlika">Maharlika</option>
            <option value="xtreme">Xtreme</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="escala" className="form-label" style={{ color: 'white' }}>Escala URL</label>
          <input type="text" className="form-control" id="escala" name="escala" value={formData.escala} onChange={handleChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="maharlika" className="form-label" style={{ color: 'white' }}>Maharlika URL</label>
          <input type="text" className="form-control" id="maharlika" name="maharlika" value={formData.maharlika} onChange={handleChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="xtreme" className="form-label" style={{ color: 'white' }}>Xtreme Cash URL</label>
          <input type="text" className="form-control" id="xtreme" name="xtreme" value={formData.xtreme} onChange={handleChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="gc" className="form-label" style={{ color: 'white' }}>GC Link</label>
          <input type="text" className="form-control" id="gc" name="gc" value={formData.gc} onChange={handleChange} />
        </div>
        <button type="submit" className="btn btn-primary">Save</button>
      </form>
    </div>
  );
}

export default ModifyLinks;
